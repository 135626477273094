//--ADD CURRENT CLASS TO LEXIKON TARGET PAGE -> NAVIGATION
//--first check if elements with specific ID exists, then check mediaWidth
if ($('#nav').length > 0) {
    window.onload = function () {
        setActive1();
    };
    function setActive1() {
        aObj = document.getElementById('nav').getElementsByTagName('a');
        for (i = 0; i < aObj.length; i++) {
            if (document.location.href.indexOf(aObj[i].href) >= 0) {
                aObj[i].className = 'current-page';
            }
        }
    }

}

jQuery(document).ready(function($){
    //-- LEXIKON TARGET PAGE -> SCROLL USER ON A CHOSEN LETTER
    var navigator = document.getElementsByTagName('.alfabet-menu a[href^="#"]');
    //--be sure that the navigation is clicked
    if (navigator) {
        $('.alfabet-menu a[href^="#"]').click(function (e) {
            $('html,body').animate({scrollTop: $(this.hash).offset().top - 10}, 1000);
            return false;
            e.preventDefault();
        });
    }

    //--NAVIGATION ON LEXIKON PAGE
    //--first check if elements with specific ID exists, then check mediaWidth
    if ($('#nav-anchor').length > 0) {
        if (matchMedia) {
            var stick = window.matchMedia("(min-width: 1024px)");
            stick.addListener(WidthChangeStick);
            WidthChangeStick(stick);
        }
        function WidthChangeStick(stick) {
            if (stick.matches) {
                $(window).scroll(function () {
                    var window_top = $(window).scrollTop();
                    var div_top = $('#nav-anchor').offset().top;
                    if (window_top > div_top) {
                        $('#lex-nav').addClass('stick');
                        $('.lexikon-box').addClass('padd');
                    } else {
                        $('#lex-nav').removeClass('stick');
                        $('.lexikon-box').removeClass('padd');
                    }
                });
            }
            else {
                $(window).scroll(function () {
                    $('.lexikon-box').removeClass('padd');
                });
            }
        }
    }

    //--LEXIKON MAIN PAGE NAVIGATION
    var navigator_main = document.getElementsByTagName('#lex-nav li a[data-range^="#"]');
    //--be sure that navigation is clicked
    if (navigator_main) {
        //--clickable navigation -- REMOVED
       /* $('#lex-nav li a[href^="#"]').click(function (e) {
            $('html,body').animate({scrollTop: $(this.hash).offset().top - 90}, 700);
            return false;
            e.preventDefault();
        });*/

        var aChildren = $("#lex-nav div li").children(); // find the a children of the list items
        var aArray = []; // create the empty aArray
        for (var i = 0; i < aChildren.length; i++) {
            var aChild = aChildren[i];
            var ahref = $(aChild).attr('data-range');
            aArray.push(ahref);
        } // this for loop fills the aArray with attribute href values

        $(window).scroll(function () {
            var windowPos = $(window).scrollTop(); // get the offset of the window from the top of page
            var windowHeight = $(window).height(); // get the height of the window
            var docHeight = $(document).height();
            for (var i = 0; i < aArray.length; i++) {
                var theID = aArray[i];
                var divPos = $(theID).offset().top - 100; // get the offset of the div from the top of page
                var divHeight = $(theID).height(); // get the height of the div in question
                if (windowPos >= divPos && windowPos < (divPos + divHeight)) {
                    $("a[data-range='" + theID + "']").addClass("nav-active");
                } else {
                    $("a[data-range='" + theID + "']").removeClass("nav-active");
                }
            }
            if (windowPos + windowHeight == docHeight) {
                if (!($("#lex-nav li:last-child a")).hasClass("nav-active")) {
                    var navActiveCurrent = $(".nav-active").attr("href");
                    $("a[href='" + navActiveCurrent + "']").removeClass("nav-active");
                    $("#lex-nav li:last-child a").addClass("nav-active");
                }
            }
        });
    }
    //END OF NAVIGATION IN LEXIKON PAGE
});

