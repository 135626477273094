//--ACTIVATE SLY SLIDER STARTSITE
$(function($){
    'use strict';
    (function () {
        var $frame = $('#effects');
        var $wrap  = $frame.parent();
        // Call Sly on frame
        $('.slidee').show();
        $frame.sly({
            horizontal:1,
            itemNav: 'centered',
            smart: 1,
            //activateMiddle: 2,
            // activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt:1,
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 1,
            speed: 300,
            elasticBounds: 1,
            easing: 'linear',
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1,
            //cycleBy:       'items', // Enable automatic cycling by 'items' or 'pages'.
            //cycleInterval: 3200,
            // Buttons
            prev: ('.prev_s'),
            next: ('.next_s')
        });
    }());
});

//--ACTIVATE SLY SLIDER STARTSITE POPULAR-SOFTWARE
$(function($){
    'use strict';
    (function () {
        var $frame = $('#ps-effects');
        var $wrap  = $frame.parent();
        // Call Sly on frame
        $('.slidee').show();
        $frame.sly({
            horizontal:1,
            itemNav: 'centered',
            smart: 1,
            //activateMiddle: 2,
            // activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt:1,
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 1,
            speed: 300,
            elasticBounds: 1,
            easing: 'linear',
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1,
            //cycleBy:       'items', // Enable automatic cycling by 'items' or 'pages'.
            //cycleInterval: 3200,
            // Buttons
            prev: ('#prev_s_ps'),
            next: ('#next_s_ps')
        });
    }());
});

//--ACTIVATE SLY SLIDER STARTSITE PACKAGES-OFFERS
$(function($){
    'use strict';
    (function () {
        var $frame = $('#po-effects');
        var $wrap  = $frame.parent();
        // Call Sly on frame
        $('.slidee').show();
        $frame.sly({
            horizontal:1,
            itemNav: 'centered',
            smart: 1,
            //activateMiddle: 2,
            // activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt:1,
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 1,
            speed: 300,
            elasticBounds: 1,
            easing: 'linear',
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1,
            //cycleBy:       'items', // Enable automatic cycling by 'items' or 'pages'.
            //cycleInterval: 3200,
            // Buttons
            prev: ('#prev_s_po'),
            next: ('#next_s_po')
        });
    }());
});

//--ACTIVATE SLY SLIDER STARTSITE LAST BOUGHT PRODUCTS
$(function($){
    'use strict';
    (function () {
        var $frame = $('#bp-effects');
        var $wrap  = $frame.parent();
        // Call Sly on frame
        $('.slidee').show();
        $frame.sly({
            horizontal:1,
            itemNav: 'centered',
            smart: 1,
            //activateMiddle: 2,
            // activateOn: 'click',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt:1,
            scrollBar: $wrap.find('.scrollbar'),
            scrollBy: 1,
            speed: 300,
            elasticBounds: 1,
            easing: 'linear',
            dragHandle: 1,
            dynamicHandle: 1,
            clickBar: 1,
            //cycleBy:       'items', // Enable automatic cycling by 'items' or 'pages'.
            //cycleInterval: 3200,
            // Buttons
            prev: ('#prev_s_ps'),
            next: ('#next_s_ps')
        });
    }());
});
