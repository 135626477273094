//--REMOVE ALL AND/OR SINGLE SEARCH QUERIES
function register_buttons(){
    $('.search-words .close').on('click', function() {
        $(this).parent().remove();
        $('.input__field').val('');
        //console.log("hi");
        var params = [];
        $('.search-words .q').each(function(idx, e) {
            params.push($(e).clone().text().trim());
        });
        refresh_search({'filter_by_q': params.join(' ')});
    });
    $('.search-words .remove-all').on('click', function() {
        refresh_search({'filter_by_q': ''});
    });
}
function refresh_search(params) {
    $.getJSON(location.href, params, function (data) {
        if ( data.content ) {
            $('.content').fadeOut('fast').html(data.content).fadeIn('fast');
            register_buttons();
            register_button_cat();
        }
    });
}

//--REMOVE CATEGORY SEARCH QUERY
function register_button_cat() {
    $('.q__cat .close-cat').on('click', function () {
        //$(this).parent().remove();
        location.href = "/alle-kategorien";
    });
}



$(document).ready(function() {
    //--SEARCH QUERIES
    register_button_cat();
    register_buttons();
    //--MAIN MENU
    $(".mobile-menu").click(function() {
        $(".menu-icon").toggleClass("active");
        $("body").toggleClass("_activebody");
        $(".overlay-menu").toggleClass("open");
    });
    
    //--SHOW MOBILE SEARCH
    $(".mobile-search").click(function() {
        $(".mobile-form").fadeIn(300);
        $('.mobile-form input').focus();
    });
    //--CLOSE SEARCH
    $(".m-close").click(function() {
        $(".mobile-form").fadeOut(300);
    });

    //--REMOVE p EMPTY TAGS
    $('.product-description section p').each(function() {
        var $this = $(this);
        if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

    //--ACTIVATE ZOOM ON PRODUKTVORASCHAU
    var zoomConfigImg = {
        gallery: 'gallery_01',
        cursor: 'pointer',
        galleryActiveClass: 'active',
        scrollZoom: true,
        borderSize: 3,
        borderColour: "#3c424e",
        zoomWindowOffetx: 22,
        zoomWindowOffety: -5,
        zoomWindowWidth: 350,
        easing: true,
        loadingIcon: 'http://www.elevateweb.co.uk/spinner.gif'
    };
    var imageI = $('#gallery_01 a');
    var zoomImageImg = $('#zoom_feature');
    if(imageI.first().hasClass("dynamic_img_box"))
    {
    	$('#dynamic_img_box_content').html(imageI.first().find('.dynamic_img_box_hidden_content').html());
    	$('#dynamic_img_box_content').show();
    	$('.left-sidebar .product-gallery .frame').addClass('hideglas');
    }
    else
    	zoomImageImg.elevateZoom(zoomConfigImg);//initialise zoom

    imageI.on('click', function(){
        // Remove old instance od EZ
        $('.zoomContainer').remove();
        zoomImageImg.removeData('elevateZoom');
        
        if($(this).hasClass("dynamic_img_box"))
        {
        	$('#dynamic_img_box_content').html($(this).first().find('.dynamic_img_box_hidden_content').html());
        	$('#dynamic_img_box_content').show();
        	$('.left-sidebar .product-gallery .frame').addClass('hideglas');
        }
        else
        {
        	$('.left-sidebar .product-gallery .frame').removeClass('hideglas');
        	$('#dynamic_img_box_content').hide()
	        // Update source for images
	        zoomImageImg.attr('src', $(this).data('image'));
	        zoomImageImg.data('zoom-image', $(this).data('zoom-image'));
	        // Reinitialize EZ
	        zoomImageImg.elevateZoom(zoomConfigImg);
        }
    });





    //--FOR OVERLAY EFFECT ON FOCUS SEARCH
    $(".input__field").focus(function(){
        $('body').addClass("overlay-search");
    }).blur(function(){
        $('body').removeClass("overlay-search");
    });


    //-- SINGLE PRODUKTINHLAT EXTENDED BOX FOR SMALL DEVICES
    if (matchMedia) {
        var mqm = window.matchMedia("(max-width: 1024px)");
        mqm.addListener(WidthChange);
        WidthChange(mqm);
    }
    function WidthChange(mqm) {
        if (mqm.matches) {
            $('.overview-more').click(function () {
                $(this).hide();
                $('.overview-less').css('display','block');
                $('div.view').toggleClass('expand');
            });

            $('.overview-less').click(function () {
                $(this).hide();
                $('.overview-more').css('display','block');
                $('div.view').toggleClass('expand');
                $("html, body").animate({ scrollTop: 0 }, 600);
            });
        }
    }

    //FIRE LIGHTBOX ON IMAGE CLICK IN MOBILE PRODUKTVORSCHAU
     $('.popup-gallery').magnificPopup({
     delegate: 'a',
     type: 'image',
     callbacks: {
     elementParse: function(item) {
     console.log(item.el.context.className);
     item.type = 'image',
     item.tLoading = 'Loading image #%curr%...',
     item.mainClass = 'mfp-img-mobile',
     item.image = {
     tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
     }
     }
     },
     gallery: {
     enabled: true,
     navigateByImgClick: true,
     preload: [0,1] // Will preload 0 - before current, and 1 after the current image
     }
     });
     
    //FIRE LIGHTBOX ON IMAGE CLICK IN DESKTOP PRODUKTVORSCHAU 
     $('.dtpopup-gallery').magnificPopup({
     delegate: 'a',
     type: 'image',
     callbacks: {
     elementParse: function(item) {
     console.log(item.el.context.className);
     item.type = 'image',
     item.tLoading = 'Loading image #%curr%...',
     item.mainClass = 'mfp-img-mobile',
     item.image = {
     tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
     }
     }
     },
     gallery: {
     enabled: true,
     navigateByImgClick: true,
     preload: [0,1] // Will preload 0 - before current, and 1 after the current image
     }
     });


    //SLIDER ON MOBILE PRODUKTVORSCHAU
    $('.gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.navgal'
    });
    $('.navgal').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.gallery',
        arrows:false,
        //centerMode: true,
        focusOnSelect: true
    });
    
    $(".numerictextbox").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
             // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) || 
             // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
                 // let it happen, don't do anything
                 return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
});



//--ADD CURRENT CLASS TO BIG OVERLY MENU -> NAVIGATION
//$(document).ready(function() {
//     // get current URL path and assign 'active' class
//     var pathname = window.location.pathname;
//     $('.menu-box > ul > li > a[href="'+pathname+'"]').parent().addClass('active');
//});
//
//window.addEventListener("load", function() { window.scrollTo(0, 1); });
//// Detect Device Type
//if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//    // When ready, auto-scroll 1px to hide URL bar
//    window.addEventListener("load", function () {
//        // Set a timeout...
//        setTimeout(function () {
//            // Hide the address bar!
//            window.scrollTo(0, 1);
//        }, 0);
//    });
//}

//workaround für manychats ::before
$(window).on('load', function () {$("._3L7815pw").addClass("no_before");});

var scriptsLoaded = true;