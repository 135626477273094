// jQuery
require("jquery");

// NPM modules
require("sweetalert2");

// legacy js files
require("../../lib/jquery.elevatezoom");
require("../../lib/jquery.magnific-popup.min");
require("../../lib/jquery.qtip");
require("../../lib/jquery.slides.min");
require("../../lib/js.cookie");
require("../../lib/lexikon-setup");
require("../../lib/slick.min");
require("../../lib/sly-activation");
require("../../lib/sly-slider");
require("../../lib/w-activation");

// sass files
require("../../sass/style.scss");

